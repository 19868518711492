import React, { Component } from "react";
import Img from "gatsby-image";
import moment from "moment";

export default class Blogs extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="blogs-section" id="Blogs">
        <div className="container">
          <div className="section-head">
            <h2>How to Get Started</h2>
          </div>
          <ul
            className={`blogs-list ${data.edges.length < 5 ? "few-blogs" : ""}`}
          >
            {data.edges.map((item, index) => {
              return (
                <li key={index} className="item">
                  <div className="row">
                    <div className="left col-md-5 col-lg-4 mb-3">
                    {item.node.featureImage ? (
                      <Img
                        fluid={item.node.featureImage.fluid}
                        objectFit="cover"
                        objectPosition="50% 50%"
                      />
                    ) : (
                      <div className="no-image"></div>
                    )}
                  </div>
                    <div className="left col-md-7 col-lg-8">
                      <div className="details">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.node.description.childMarkdownRemark.html
                            }}/>
                      </div>
                    </div>
                </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}
