import React from "react";
import { InlineWidget } from "react-calendly";
const App = () => {
  return (
    <div className="app" id="Calendar">   
      <div className="container" >
        <div className="section-head">
          <h2 className="text-center">Schedule Time with Me</h2>
        </div>
        <InlineWidget
            iframeTitle="Calendly Scheduling Page"
            pageSettings={{
              backgroundColor: 'FBEEC1',
              hideEventTypeDetails: false,
              hideGdprBanner: true,
              hideLandingPageDetails: true,
              primaryColor: '000000',
              textColor: '000000',
            }}
            styles={{
              height: '750px',
              position: 'inherit',
              margin: '100px 50px 0 0'
            }}
            url="https://calendly.com/patriciacaplancappm"
/>
    </div>
    </div>
  );
};

export default App;

